import { COMBINE_EVENTS_EQUATION, DATE_PICKER_VALUE, Metrics } from "enum/enums";
import React, { useContext, useMemo } from "react";
import { CombineEvents, formatTime, getEventCountSum, kpiCalculation } from "lib/utils";
import styles from "./AnalyticSizingCards.module.scss";
import { AnalyticsPropsContextInterface } from "interface/interface";
import AnalyticsCard from "atoms/analyticsCard/AnalyticsCard";
import AnalyticsPropsContext from "context/AnalyticsPropsContext";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { SWAN_DEMO_STORE } from "lib/Constants";
import { FAILED_DATA, SUCCESS_DATA } from "lib/ScansData";

const AnalyticSizingCards = () => {
	const { datePickerValue, measurementSuccessData, scanStartedData, phoneDetectAutoData, measurementFailedData, distinctProductViewData, scanCompletionTimeData, sizingData, cameraActivationFailed } =
		useContext(AnalyticsPropsContext) as AnalyticsPropsContextInterface;
	const { isInternalUser, userDetails } = useSelector((state: RootState) => state.auth);

	const AVERAGE_TIME_SPENT_PER_SCAN_DATA = useMemo(() => {
		return CombineEvents(scanCompletionTimeData, CombineEvents(measurementSuccessData, measurementFailedData, COMBINE_EVENTS_EQUATION.ADD), COMBINE_EVENTS_EQUATION.DIVIDE);
	}, [scanCompletionTimeData, measurementSuccessData]);

	const MEASUREMENT_SUCCESS_DATA = useMemo(() => {
		return userDetails?.store_name === SWAN_DEMO_STORE && datePickerValue === DATE_PICKER_VALUE.THIRTY_DAYS ? SUCCESS_DATA : measurementSuccessData;
	}, [userDetails, measurementSuccessData,datePickerValue]);

	const MEASUREMENT_FAILED_DATA = useMemo(() => {
		return userDetails?.store_name === SWAN_DEMO_STORE && datePickerValue === DATE_PICKER_VALUE.THIRTY_DAYS ? FAILED_DATA : measurementFailedData;
	}, [userDetails, measurementFailedData,datePickerValue]);

	const TOTAL_SCAN = useMemo(() => getEventCountSum(measurementSuccessData) + getEventCountSum(measurementFailedData) || 0, [measurementSuccessData, measurementFailedData]);

	const SUCCES_SCAN_PERCENTAGE = useMemo(() => {
		{
			return kpiCalculation(getEventCountSum(MEASUREMENT_SUCCESS_DATA),(getEventCountSum(MEASUREMENT_SUCCESS_DATA) + getEventCountSum(MEASUREMENT_FAILED_DATA))||0 , true) || 0;
		}
	}, [measurementSuccessData, measurementFailedData, TOTAL_SCAN,MEASUREMENT_SUCCESS_DATA,MEASUREMENT_SUCCESS_DATA]);
	
	// const PHONE_DATA = useMemo(() => {
	// 	{
	// 		return (
	// 			kpiCalculation(
	// 				getEventCountSum(phoneDetectAutoData),
	// 				getEventCountSum(phoneDetectManualData) + getEventCountSum(phoneDetectAutoData),
	// 				true,
	// 			) || 0
	// 		);
	// 	}
	// }, [phoneDetectManualData, phoneDetectAutoData]);

	return (
		<div className={`${styles.cardList} flex-wrap`}>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.SCANS}
					value={getEventCountSum(measurementSuccessData) + getEventCountSum(measurementFailedData) || 0}
					data={CombineEvents(measurementSuccessData, measurementFailedData, COMBINE_EVENTS_EQUATION.ADD) || []}
					datePickerValue={datePickerValue}
					showGraph
					InfoIcon
					InfoIconTitle="Total number of scans, successful or unsuccessful."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.SIZING_SUGGESTIONS}
					data={sizingData}
					datePickerValue={datePickerValue}
					showGraph
					InfoIcon
					value={getEventCountSum(sizingData) || 0}
					InfoIconTitle="Total number of sizing suggestions which were generated."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.AVERAGE_TIME_SPENT_PER_SCAN}
					data={AVERAGE_TIME_SPENT_PER_SCAN_DATA}
					datePickerValue={datePickerValue}
					type={Metrics.AVERAGE_TIME_SPENT_PER_SCAN}
					value={kpiCalculation(getEventCountSum(scanCompletionTimeData), TOTAL_SCAN) ? formatTime(+kpiCalculation(getEventCountSum(scanCompletionTimeData), TOTAL_SCAN)) : 0}
					showGraph
					InfoIcon
					InfoIconTitle="Average time users spend to complete a scan."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.SUCCESS_SCAN}
					value={SUCCES_SCAN_PERCENTAGE || 0}
					data={MEASUREMENT_SUCCESS_DATA || []}
					dataTwo={MEASUREMENT_FAILED_DATA || []}
					datePickerValue={datePickerValue}
					showGraph
					InfoIcon
					InfoIconTitle="Percentage of successful scans out of the total number initiated."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.AUTOMATIC_PHONE_DETECTION}
					value={getEventCountSum(phoneDetectAutoData) || 0}
					showGraph
					data={phoneDetectAutoData}
					datePickerValue={datePickerValue}
					InfoIcon
					InfoIconTitle="Percentage of phones accurately & automatically detected."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.CAMERA_ACTIVATION_RATE}
					data={CombineEvents(cameraActivationFailed, scanStartedData, COMBINE_EVENTS_EQUATION.DIVIDE)}
					datePickerValue={datePickerValue}
					value={kpiCalculation(getEventCountSum(cameraActivationFailed), getEventCountSum(scanStartedData), true) || 0}
					showGraph
					InfoIcon
					InfoIconTitle="Percentage of users who begin the scan and whose camera is successfully accessed."
				/>
			</div>
			{isInternalUser && (
				<>
					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.SCAN_SUCCESS_COMPLETION_RATE + " (SWAN only)"}
							data={CombineEvents(measurementSuccessData, scanStartedData, COMBINE_EVENTS_EQUATION.DIVIDE)}
							datePickerValue={datePickerValue}
							value={kpiCalculation(getEventCountSum(measurementSuccessData), getEventCountSum(scanStartedData), true) || 0}
							showGraph
							InfoIcon
							InfoIconTitle="The percentage of scans successfully completed. Seems to double up with Scan Success?"
						/>
					</div>
					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.SCAN_COMPLETE_RATE + " (SWAN only)"}
							value={kpiCalculation(getEventCountSum(measurementSuccessData), getEventCountSum(scanStartedData), true) || 0}
							data={measurementSuccessData}
							datePickerValue={datePickerValue}
							showGraph
							InfoIcon
							InfoIconTitle="The percentage of successful scans out of all attempted scans."
						/>
					</div>
					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.SCAN_JOURNEY_COMPLETION_RATE + " (SWAN only)"}
							data={CombineEvents(CombineEvents(measurementSuccessData, measurementFailedData, COMBINE_EVENTS_EQUATION.ADD), scanStartedData, COMBINE_EVENTS_EQUATION.DIVIDE)}
							datePickerValue={datePickerValue}
							value={kpiCalculation(TOTAL_SCAN, getEventCountSum(scanStartedData), true) || 0}
							showGraph
							InfoIcon
							InfoIconTitle="Percentage of scan journeys completed from start to measurements."
						/>
					</div>
					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.SCAN_INITIATION_RATE + " (SWAN only)"}
							data={CombineEvents(scanStartedData, distinctProductViewData, COMBINE_EVENTS_EQUATION.DIVIDE)}
							value={kpiCalculation(getEventCountSum(scanStartedData), getEventCountSum(distinctProductViewData), true) || 0}
							datePickerValue={datePickerValue}
							showGraph
							InfoIcon
							InfoIconTitle="Rate at which users start the scanning process."
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default AnalyticSizingCards;
