export const FAILED_DATA=[
    { event_date: '2024-11-26 13:56:11.021246', count: 1 },
    { event_date: '2024-11-25 13:56:11.021246', count: 1 },
    { event_date: '2024-11-24 13:56:11.021246', count: 1 },
    { event_date: '2024-11-23 13:56:11.021246', count: 1 },
    { event_date: '2024-11-22 13:56:11.021246', count: 2 },
    { event_date: '2024-11-21 13:56:11.021246', count: 1 },
    { event_date: '2024-11-20 13:56:11.021246', count: 1 },
    { event_date: '2024-11-19 13:56:11.021246', count: 2 },
    { event_date: '2024-11-18 13:56:11.021246', count: 2 },
    { event_date: '2024-11-17 13:56:11.021246', count: 1 },
    { event_date: '2024-11-16 13:56:11.021246', count: 1 },
    { event_date: '2024-11-15 13:56:11.021246', count: 2 },
    { event_date: '2024-11-14 13:56:11.021246', count: 2 },
    { event_date: '2024-11-13 13:56:11.021246', count: 1 },
    { event_date: '2024-11-12 13:56:11.021246', count: 2 },
    { event_date: '2024-11-11 13:56:11.021246', count: 1 },
    { event_date: '2024-11-10 13:56:11.021246', count: 1 },
    { event_date: '2024-11-09 13:56:11.021246', count: 1 },
    { event_date: '2024-11-08 13:56:11.021246', count: 1 },
    { event_date: '2024-11-07 13:56:11.021246', count: 2 },
    { event_date: '2024-11-06 13:56:11.021246', count: 3 },
    { event_date: '2024-11-05 13:56:11.021246', count: 2 },
    { event_date: '2024-11-04 13:56:11.021246', count: 2 },
    { event_date: '2024-11-03 13:56:11.021246', count: 1 },
    { event_date: '2024-11-02 13:56:11.021246', count: 1 },
    { event_date: '2024-11-01 13:56:11.021246', count: 2 },
    { event_date: '2024-10-31 13:56:11.021246', count: 2 },
    { event_date: '2024-10-30 13:56:11.021246', count: 2 },
    { event_date: '2024-10-29 13:56:11.021246', count: 1 },
    { event_date: '2024-10-28 13:56:11.021246', count: 2 }
  ]

  export const SUCCESS_DATA=[
    { event_date: '2024-11-26 13:56:11.021246', count: 37 },
    { event_date: '2024-11-25 13:56:11.021246', count: 40 },
    { event_date: '2024-11-24 13:56:11.021246', count: 10 },
    { event_date: '2024-11-23 13:56:11.021246', count: 4 },
    { event_date: '2024-11-22 13:56:11.021246', count: 93 },
    { event_date: '2024-11-21 13:56:11.021246', count: 48 },
    { event_date: '2024-11-20 13:56:11.021246', count: 41 },
    { event_date: '2024-11-19 13:56:11.021246', count: 75 },
    { event_date: '2024-11-18 13:56:11.021246', count: 61 },
    { event_date: '2024-11-17 13:56:11.021246', count: 8 },
    { event_date: '2024-11-16 13:56:11.021246', count: 49 },
    { event_date: '2024-11-15 13:56:11.021246', count: 98 },
    { event_date: '2024-11-14 13:56:11.021246', count: 54 },
    { event_date: '2024-11-13 13:56:11.021246', count: 36 },
    { event_date: '2024-11-12 13:56:11.021246', count: 95 },
    { event_date: '2024-11-11 13:56:11.021246', count: 39 },
    { event_date: '2024-11-10 13:56:11.021246', count: 25 },
    { event_date: '2024-11-09 13:56:11.021246', count: 30 },
    { event_date: '2024-11-08 13:56:11.021246', count: 39 },
    { event_date: '2024-11-07 13:56:11.021246', count: 50 },
    { event_date: '2024-11-06 13:56:11.021246', count: 117 },
    { event_date: '2024-11-05 13:56:11.021246', count: 96 },
    { event_date: '2024-11-04 13:56:11.021246', count: 69 },
    { event_date: '2024-11-03 13:56:11.021246', count: 44 },
    { event_date: '2024-11-02 13:56:11.021246', count: 25 },
    { event_date: '2024-11-01 13:56:11.021246', count: 74 },
    { event_date: '2024-10-31 13:56:11.021246', count: 86 },
    { event_date: '2024-10-30 13:56:11.021246', count: 55 },
    { event_date: '2024-10-29 13:56:11.021246', count: 37 },
    { event_date: '2024-10-28 13:56:11.021246', count: 58 }
  ]
