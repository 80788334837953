export const { REACT_APP_BASE_URL, REACT_APP_POSTHOG_PERSONAL_API_KEY, REACT_APP_POSTHOG_PROJECT_ID, REACT_APP_POSTHOG_URL, REACT_APP_WEBSOCKET_URL, REACT_APP_ACCESS_KEY,REACT_APP_PUBLIC_MODEL_VIEW_URL } = process.env;

export const checkEmailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TODAY_DATE = new Date();
export const ROUTES = {
	HOME: "/",
	LOGIN: "/login",
	AUTH: "/auth",
	NOT_MATCH: "*",
	SIGNUP: "/signup",
	ONBOARDING: "/onboarding",
	ANALYTICS: "/analytics",
	USER_VERIFY: "/user-verify",
	CUSTOMERS_LIST: "/customers-list",
	PRODUCTS: "/products",
	FIT_MODEL_ONBOARDING: "/fit-model-onboarding",
	TRY_ON_RECENT_IMAGES: "/try-on/recent-images",
	TRY_ON_ONBOARDING: "/try-on/onboarding",
	TRY_ON_SELECTION: "/try-on/selection",
	SIZING_START: "/sizing/start",
	MEASUREMENT_DEFINITION: "/measurement/definition",
	ONBOARDING_FIRST_STEP: "/onboarding/first-step",
	ONBOARDING_SECOND_STEP: "/onboarding/second-step",
	ONBOARDING_THIRD_STEP: "/onboarding/third-step",
	SIZING_ONBOARDING: "/sizing-onboarding",
	MEASUREMENT_GROUPS: "/measurement/groups",
	MEASUREMENT_TABLE: "/measurements",
	CUSTOM_DETAILS:"/custom/details"
	
};

export const SOMETHING_WENT_WRONG = "Something went wrong!";

export const API_END_POINTS = {
	DASHBOARD_ANALYTICS: "/dashboard/analytics",
	DASHBOARD_METRICS: "/dashboard/metrics",
	AUTHORIZE_USER: "/auth/link/authorize",
	AUTH_CALLBACK: "/auth/link/callback",
	CUSTOMER: "/customer",
	DASHBOARD_ORDERS: "/dashboard/orders",
	INTERNAL_AUTH: "/internal/auth",
	ONBOARD: "/onboard",
	REGISTER_CUSTOMER: "/customer/register",
	CUSTOMER_APP_INSTALL: "/customer/appinstall",
	FIT_MODELS: "/fitmodel",
	FIT_MODEL_MEASUREMENTS: "/fitmodel/measurements",
	CUSTOM: {
		customFit: "/customfit",
		customFitMeasurements:'/customfit/measurements',
		customFitNotes:"/customfit/notes",
		customFitExport: '/customfit/export',
	},
	GROUPS: {
		getList: "/groups",
	},
	TECHPACK: {
		getList: "/techpack/parse/list",
		uploadTechpackFile: "/files/url",
		uploadedFileUrl: "/files/download",
		updateTechpackParse: "/techpack/parse",
		updateTechpackParseDebounce: "/techpack/parse/measurements",
		submitFeedback: "/techpack/parse/feedback",
		techpackParse: "/techpack/parse",
		techpack: "/techpack",
	},
	PRODUCT: {
		getList: "/products/all",
		techpackProductSizing: "/techpack/product-sizing",
		productOnboard: "/products/onboard",
		onboardingSummary: "/products/onboarded",
	},
	PRODUCTSIZE: {
		postData: "/techpack/product-sizing",
		commitData: "/techpack/product-sizing/commit",
	},
	MEASUREMENT: {
		getgroupList: "/measuredefs/config/groups",
		getIndividualMeasurementGroup: "/measuredefs/config/groups",
		measurement: "/measuredefs",
	},
	TRY_ON: {
		getList: "/tryon/onboard/products",
		toApprove: "/tryon/onboard/status/approve",
		getSampleImages: "/tryon/onboard/sample-images",
		tryOnTrials: "/tryon/onboard/exec",
		tryonProductDetails: "/tryon/onboard/products",
		reviewImages: "/tryon/onboard/review-images",
		tryonFinetune: "/tryon/onboard/status/finetune",
		tryonOnboard: "/tryon/onboard/status/onboard",
		tryonOnboardCheck: "/tryon/onboard/check",
		uploadTryonFile: "/files/url",
		tryonDeleteFile:"/files"
	},
};

export const hasCustomAddOnFlag = "hasCustomAddon";
export const FULFILLED = "fulfilled";
export const emailRegex = /.*@(mailinator\.com|getswan\.co)/;
export const secondEmailRegex = /\w+@[\w.]+\b\.ie\b/;
export const excludeEmails = [
	"brenhogan@gmail.com",
	"eoin@getswan.co",
	"adrianocmail@gmail.com",
	"caroline@reneruizcollection.com",
	"atabak@syze.ai",
	"support@intimatebrides.com",
	"charlottepolefit@yahoo.com",
	"neal@getswan.co",
];
export const EVENT_TIMESTAMP_KEY = "event_date";
export const SUCCESFULLY_EMAIL_SENT = "Successfully email sent";
export const INTERNAL_JWT_TOKEN = "InternalJWTToken";
export const JWT_TOKEN = "JWTToken";
import img1 from "../assets/images/measurements.jpg";
import img2 from "../assets/images/fit-modal.jpg";
import img3 from "../assets/images/tech-pack.jpg";

export const SIZING_QUICK_START_DATA = [
	{
		id: 1,
		imgageUrl: img1,
		para: "Agree on measurement definitions.",
		heading: "Measurements",
		time: "2 min",
		route: ROUTES.MEASUREMENT_GROUPS,
		altRout: ROUTES.MEASUREMENT_TABLE,
	},
	{
		id: 2,
		imgageUrl: img2,
		para: "Used when designing a garment, a fit model represents the ideal shape and dimensions of your central size.",
		heading: "Fit Model",
		time: "3 min",
		route: ROUTES.FIT_MODEL_ONBOARDING,
		altRout: ROUTES.FIT_MODEL_ONBOARDING,
	},
	{
		id: 3,
		imgageUrl: img3,
		para: "A tech pack is a file containing all the relevant garment details used to manufacture that garment. Swan will pull important garment measurements from these files.",
		heading: "Tech Packs",
		time: "6 min",
		route: ROUTES.SIZING_ONBOARDING,
		altRout: ROUTES.PRODUCTS,
	},
];
export const MeasurementRowOptions: {
	name: string;
	id: string;
	selected: boolean;
}[] = [
	{
		name: "head",
		id: "b9e1c9a5-5fda-420c-871f-3a839cc33f10",
		selected: false,
	},
	{
		name: "shoulder",
		id: "17c1329a-2f61-4636-99c8-cbbab1fc90d7",
		selected: false,
	},
	{
		name: "acrossBackShoulder",
		id: "b7c12b83-2e8d-4945-8d5b-c3d3966f268a",
		selected: false,
	},
	{
		name: "neckBase",
		id: "49df43d4-823e-4ad6-9f6e-2b44e3d780e1",
		selected: false,
	},
	{
		name: "neckCirc",
		id: "f1984e65-2a41-4cb6-8349-2193b7333f3b",
		selected: false,
	},
	{
		name: "bust",
		id: "ee2b2073-690e-40ac-a839-46ba7d2c70e2",
		selected: false,
	},
	{
		name: "torso",
		id: "0fe0de7e-7d52-485c-8659-be6fba71feab",
		selected: false,
	},
	{
		name: "underBust",
		id: "a70d99bd-2bfa-4fd3-bd10-3a9931d1e7f6",
		selected: false,
	},
	{
		name: "armLength",
		id: "c76ec6e0-5e8d-4cd6-bbd7-84f92bacd79a",
		selected: false,
	},
	{
		name: "bicep",
		id: "bf3f996c-3993-4891-9a99-a10631bfe6ce",
		selected: false,
	},
	{
		name: "elbow",
		id: "f0a671a6-9039-4c77-9e3f-a8e53afa3859",
		selected: false,
	},
	{
		name: "forearm",
		id: "a1b25dec-3e22-47fd-9f09-e17c6b19369b",
		selected: false,
	},
	{
		name: "wrist",
		id: "cc28e459-0247-4a0c-9b53-8ab7bf8a8736",
		selected: false,
	},
	{
		name: "napeToWaist",
		id: "b52fa8e0-1706-4503-9c7c-ff2ad052de4b",
		selected: false,
	},
	{
		name: "waist",
		id: "cdd9d799-1e2d-4683-b4f1-75acf619d214",
		selected: false,
	},
	{
		name: "waistBellybutton",
		id: "cdd9d799-1e2d-4683-b4f1-75acf619d2144434",
		selected: false,
	},
	{
		name: "highHip",
		id: "cdd9d799-1e2d-4683-b4f1-75acf619d3234214",
		selected: false,
	},
	{
		name: "hip",
		id: "cdd9d799-1e21hd-4683-b4f1-75acf619d214",
		selected: false,
	},
	{
		name: "inseam",
		id: "cdd9d799-1e2d-4683-b4f1ohj-75acf619d214",
		selected: false,
	},
	{
		name: "inseamToAnkle",
		id: "e292ddce-7899-4d1453a-b057-b514d58d94a4",
		selected: false,
	},
	{
		name: "knee",
		id: "e292ddce-789jth9-4d3a-b057-b514d58d94a4",
		selected: false,
	},
	{
		name: "seat",
		id: "e292ddce-7899-4d3a-b057-b514d58d94a4",
		selected: false,
	},
	{
		name: "thigh",
		id: "4aea97b1-838d-46de-af31-a276a0626aac",
		selected: false,
	},
	{
		name: "calf",
		id: "cdd9d799-1e2d-4683-b4f1-75acf619d21490",
		selected: false,
	},
	{
		name: "ankle",
		id: "a1b83077-7433-463b-9488-82906fdff672",
		selected: false,
	},
];

export const COLOURS: string[] = ["#B42318", "#026AA2", "#5925DC", "#027A48", "#B54708"];
export const BGCOLOURS: string[] = ["#FEF3F2", "#F0F9FF", "#F4F3FF", "#ECFDF3", "#FFFAEB"];
export const regex = /^(?:\d{0,7}|\d{1,6}\.\d{1}|\d{1,5}\.\d{2}|\d{1,4}\.\d{3}|\d{1,3}\.\d{4}|\d{1,2}\.\d{5}|\d{1}\.\d{6})$/;
export const TECHPACK_FILE_TYPE = {
	XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	PDF: "application/pdf",
	pdf: "pdf",
	xlsx: "xlsx",
};
export const MUI_TABLE_TYPE = {
	TECHPACK: "TECHPACK",
	FITMODEL: "FITMODEL",
	MEASUREMENT: "MEASUREMENT",
	TRY_ON: "TRY_ON",
	CUSTOM_LIST:"CUSTOM_LIST",
	CUSTOM_MEASUREMENT_LIST:"CUSTOM_MEASUREMENT_LIST"
};

export const SELECT_TYPE = {
	TECHPACK: "TECHPACK",
	FITMODEL: "FITMODEL",
	NORMAL: "NORMAL",
	MESUREMENT: "MESUREMENT",
};
export const TRY_ON_STATUS: Record<string, { name: string; color: string; bgColor: string }> = {
	fine_tuning: { name: "Fine tuning", color: "#B93815", bgColor: "#FFF4ED" },
	onboarded: { name: "Onboarded", color: "#027A48", bgColor: "#ECFDF3" },
	awaiting_approval: { name: "Awaiting Approval", color: "#175CD3", bgColor: "#EFF8FF" },
	eligible: { name: "Eligible", color: "#175CD3", bgColor: "#EFF8FF" },
	not_eligible: { name: "Not Eligible", color: "#B54708", bgColor: "#FFFAEB" },
	fine_tuned: { name: "Fine Tuned", color: "#363F72", bgColor: "#F8F9FC" },
	tryon_failed: { name: "Tryon Failed", color: "#5925DC", bgColor: "#F4F3FF" },
	approved: { name: "Approved", color: "#027A48", bgColor: "#ECFDF3" },
	// onboarded:{name:"Onboarded",color:"#175CD3",bgColor:"#EFF8FF"},
	Awaiting_Approval: { name: "Awaiting Approval", color: "#175CD3", bgColor: "#EFF8FF" },
	sent_for_elibility: { name: "Sent For Elibility", color: "#175CD3", bgColor: "#EFF8FF" },
};
export const TRY_ON_STATUS_ARRAY = [
	{ id: 0, value: "all", name: "All" },
	{ id: 1, value: "eligible", name: "Eligible" },
	// { id: 2, value: "not_eligible", name: "Not Eligible" },
	{ id: 3, value: "awaiting_approval", name: "Awaiting Approval" },
	{ id: 4, value: "fine_tuning", name: "Fine Tuning" },
	// { id: 5, value: "fine_tuned", name: "Fine Tuned" },
	{ id: 6, value: "tryon_failed", name: "Tryon Failed" },
	{ id: 7, value: "approved", name: "Approved" },
	{ id: 8, value: "onboarded", name: "Onboarded" },
];
export const TRY_ON_STATUS_FOR_OPEN: Record<string, boolean> = { awaiting_approval: true, fine_tuning: true, fine_tuned: true, approved: true, onboarded: true };

export const EXAMPLE_IMAGES = "Example images";

export const SWAN_DEMO_STORE= "Swan Demo Store (demo.swanvision.ai)"